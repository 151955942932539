import Vue from 'vue'
import Router from 'vue-router'
import strore from './store'
import i18n from './i18n'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      name: i18n.t('menu.login'),
      path: '/login',
      meta: { layout: 'AuthLayout', auth: false },
      component: () => import('@/views/auth/login'),
    },
    {
      name: i18n.t('menu.register'),
      path: '/register',
      meta: { layout: 'AuthLayout', auth: false },
      component: () => import('@/views/auth/register'),
    },
    {
      name: i18n.t('menu.main'),
      path: '/',
      meta: { layout: 'DashboardLayout', auth: true },
      component: () => import('@/views/dashboard/Index'),
    },

    // Pages
    {
      name: i18n.t('menu.user'),
      path: '/pages/user',
      meta: { layout: 'DashboardLayout', auth: true },
      component: () => import('@/views/dashboard/pages/UserProfile'),
    },
    {
      name: i18n.t('menu.rates'),
      path: '/pages/rates',
      meta: { layout: 'DashboardLayout', auth: true },
      component: () => import('@/views/dashboard/pages/Rates'),
    },
    {
      name: i18n.t('menu.social-chats'),
      path: '/pages/social-chats',
      meta: { layout: 'DashboardLayout', auth: true },
      component: () => import('@/views/dashboard/pages/SocialChats'),
    },
    {
      name: i18n.t('menu.notifications'),
      path: '/pages/notifications',
      meta: { layout: 'DashboardLayout', auth: true },
      component: () => import('@/views/dashboard/pages/Notifications'),
    },
    {
      name: i18n.t('menu.clients'),
      path: '/pages/clients',
      meta: { layout: 'DashboardLayout', auth: true },
      component: () => import('@/views/dashboard/pages/Clients'),
    },
    {
      name: i18n.t('menu.users'),
      path: '/pages/users',
      meta: { layout: 'DashboardLayout', auth: true },
      component: () => import('@/views/dashboard/pages/Users'),
    },
    {
      name: i18n.t('menu.products'),
      path: '/pages/products',
      meta: { layout: 'DashboardLayout', auth: true },
      component: () => import('@/views/dashboard/pages/Products'),
    },
    {
      name: i18n.t('menu.goods'),
      path: '/pages/shoq-doner-goods',
      meta: { layout: 'DashboardLayout', auth: true },
      component: () => import('@/views/dashboard/pages/Goods'),
    },
    {
      name: i18n.t('menu.orders'),
      path: '/pages/orders',
      meta: { layout: 'DashboardLayout', auth: true },
      component: () => import('@/views/dashboard/pages/Orders'),
    },
    {
      name: i18n.t('menu.suppliers'),
      path: '/pages/suppliers',
      meta: { layout: 'DashboardLayout', auth: true },
      component: () => import('@/views/dashboard/pages/Suppliers'),
    },

    // Maps
    {
      name: i18n.t('menu.maps'),
      path: '/maps/google-maps',
      meta: { layout: 'DashboardLayout', auth: true },
      component: () => import('@/views/dashboard/maps/GoogleMaps'),
    },
  ],
})

router.beforeEach((to, from, next) => {
  const currentUser = strore.getters['user/isAuth']
  const requiredAuth = to.matched.some((record) => record.meta.auth)

  if (requiredAuth && !currentUser) {
    next('/login')
  } else {
    next()
  }
})

export default router
