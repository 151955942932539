import axios from 'axios'
import jsCookie from 'js-cookie'
import Store from '@/store'

const AuthApiConfig = {
  baseURL: process.env.VUE_APP_AUTH_URL || 'https://crm-shoqdoner.a-lux.dev/api',
  headers: {
    'Content-Type': 'application/json',
  },
}

const MainApiConfig = {
  baseURL: process.env.VUE_APP_BASE_URL || 'https://doner.a-lux.dev/api/crm',
  headers: {
    'Content-Type': 'application/json',
  },
}

const AuthApiInstance = axios.create(AuthApiConfig)
const ApiInstance = axios.create(MainApiConfig)

//MAIN API INTERCEPTORS
ApiInstance.interceptors.request.use((config) => {
  const authToken = jsCookie.get('token')

  if (authToken) {
    config.headers.Authorization = `Bearer ${authToken}`
  }

  return config
})

ApiInstance.interceptors.response.use(
  (response) => {
    return response.data
  },

  (error) => {
    const errorData = error.response.data.errors

    if (error.response.status === 401) {
      return Store.dispatch('user/clearUserData')
    }

    if (errorData) {
      return Promise.reject(errorData)
    } else {
      return Promise.reject(error.response.data)
    }
  },
)

//AUTH API INTERCEPTORS
AuthApiInstance.interceptors.request.use((config) => {
  const authToken = jsCookie.get('token')

  if (authToken) {
    config.headers.Authorization = `Bearer ${authToken}`
  }

  return config
})

AuthApiInstance.interceptors.response.use(
  (response) => {
    return response.data
  },

  (error) => {
    const errorData = error.response.data.errors

    if (error.response.status === 401) {
      return Store.dispatch('user/clearUserData')
    }

    if (errorData) {
      return Promise.reject(errorData)
    } else {
      return Promise.reject(error.response.data)
    }
  },
)

export { ApiInstance, AuthApiInstance }
